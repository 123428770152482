import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/privacy/hero';
import Body from '../components/privacy/body';

const PrivacyPage = () => (
  <Layout>
    <SEO title='Privacy' />
    <Hero />
    <Body />
  </Layout>
);

export default PrivacyPage;
