import React from 'react';

const Hero = () => (
  <div className='privacy-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Privacy
        </h1>

        <p className='font-sans font-normal text-white mb-4 leading-snug'>
          We know that if we want to be trusted with your most personal information, we have to earn that trust. Every one of us at the NL Genome Project is committed to protecting the privacy and security of your information, day in and day out.
        </p>

        <h4 className='font-soft font-semibold text-blue-900 mb-4 leading-snug'>
          Data is stored in Canada and is controlled in Newfoundland and Labrador by Sequence Bio.
        </h4>
      </div>
    </div>
  </div>
);

export default Hero;
