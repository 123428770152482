import React from 'react';
import noEntry from '../../images/no-entry.svg';
import yourChoice from '../../images/your-choice.svg';
import sharingData from '../../images/sharing-data.svg';
import restrictedAccess from '../../images/restricted-access.svg';
import protectingYou from '../../images/protecting-you.svg';
import closeToHome from '../../images/close-to-home.svg';

const Hero = () => (
  <>
    <div className='bg-white'>
      <div className='container mx-auto py-16 px-8'>

        <div className='flex'>

          <div className=''>
            <h3 className='font-sans text-2xl lg:text-4xl font-bold text-blue mb-4'>
              Your information
            </h3>

            <p className='font-soft font-semibold text-lg text-grey-500 leading-snug mb-6'>
              We do not own your information. If you choose to take part in the NL Genome Project, you are only granting us permission to use it for the research purposes described in the Consent Form.
            </p>

            <p className='font-sans text-grey mb-4'>
              Here are the types of data we’ll use for research:
            </p>

            <ul className='ml-12 font-sans text-grey list-disc mb-4'>
              <li className='mb-2'>
                <span className='font-bold text-blue'>Genetic Information: </span>
                Your saliva sample and the data that results when it is analyzed by genotyping and sequencing.
              </li>

              <li>
                <span className='font-bold text-blue'>Health Information: </span>
                Your medical records and the information you provide in the questionnaire.
              </li>
            </ul>

            <p className='font-sans text-grey mb-4'>
              Your genetic and health information will only be identified by a random and unique code called a Study Code.
            </p>

            <ul className='ml-12 font-sans text-grey list-disc mb-2'>
              <li>
                <span className='font-bold text-blue'>Personally Identifiable Information (PII): </span>
                Information that identifies you, like your MCP number, name, and contact information. This data is never used for research purposes and is protected with strict security controls.
              </li>
            </ul>

            <ul className='ml-12 font-sans text-grey list-disc'>
              <li className='mb-2'>We collect the least amount of personal information required for specific purposes, for example to contact you or to return findings.</li>
              <li>We need this information to enroll or withdraw you from the study, provide genetic counselling support, return findings, share updates, recontact you and access your health records.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className='bg-blue-100'>
      <div className='container mx-auto py-16 px-8'>
        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={yourChoice} alt='Your choice' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>

            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              Your choice
            </h3>

            <p className='font-sans text-grey mb-4'>
              It is up to you whether you want to participate in the NL Genome Project. To help you decide, we promise to be upfront about the ways in which we will store, protect and use your data. You can also leave the project at any time without impacting your care.
            </p>
          </div>
        </div>

        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={noEntry} alt='No entry' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>

            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              No entry
            </h3>

            <p className='font-sans text-grey mb-4'>
              The NL Genome Project is committed to the highest levels of security and protects data with the same best-practice measures used by leading hospitals, banks, and governments around the world.
            </p>

            <p className='font-sans text-grey mb-4'>
              Our industry-leading security program is built to align with international cybersecurity standards including the ISO Information Security Standards (27001/2) and the NIST Cybersecurity Framework. We assess all cloud services using frameworks developed by the Cloud Security Alliance and our applications are built following the Open Web Application Security Project (OWASP). All information is encrypted using Advanced Encryption Standard (AES) 256 bit encryption. Restricted information, such as health information, is double encrypted - this means that the data is encrypted (initial encryption) and then stored in an encrypted database (double encryption).
            </p>
          </div>
        </div>


        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={protectingYou} alt='Protecting you' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>
            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              Protecting you
            </h3>

            <p className='font-sans text-grey mb-4'>
              Information that identifies you, like your name or birthday, is not used for research and is locked away in a separate encrypted database. Instead, you will only be identified by a unique Study Code when research is performed.
            </p>

            <ul className='ml-12 font-sans text-grey list-disc mb-4'>
              <li className='italic'>Did you know? The Study Code is so unique that you’d need to generate 1 billion of them per second for about 85 years to find a match.</li>
            </ul>
          </div>
        </div>


        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={closeToHome} alt='Close to home' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>
            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              Close to home
            </h3>

            <p className='font-sans text-grey mb-4'>
              All of the NL Genome Project’s data is stored in Canada utilizing cloud services backed by Canadian data centres, and is guarded by 24/7 monitoring, regular backups, intrusion detection, and audit logs for forensic analysis. Access to any data is controlled in Newfoundland and Labrador by Sequence Bio.
            </p>

            <ul className='ml-12 font-sans text-grey list-disc mb-4'>
              <li>We use the same technology as the Ottawa Hospital Research Institute, Harvard Medical School, and the US Department of Homeland Security.</li>
            </ul>
          </div>
        </div>


        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={restrictedAccess} alt='Restricted access' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>
            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              Restricted access
            </h3>

            <p className='font-sans text-grey mb-4'>
              Only authorized individuals may see information about you. Even then, they only see this information when required for very specific purposes.
            </p>

            <p className='font-sans text-grey mb-4'>
              Researchers cannot download, share or transfer data to anyone else, or use it for any purposes other than what you’ve agreed to. It is common in research to collaborate with other researchers. When this happens, they will not download or transfer any data. Instead, we bring them to the data by providing controlled and secure access to our research platform.
            </p>
          </div>
        </div>


        <div className='flex mb-12'>
          <div className='w-1/5'>
            <img src={sharingData} alt='Sharing data' className='flex-none mb-4 h-24' />
          </div>

          <div className='w-4/5'>

            <h3 className='font-sans text-2xl font-semibold text-blue mb-4'>
              Sharing data
            </h3>

            <p className='font-sans text-grey mb-4'>
              Individual data or data that identifies you will not be sold, and we will only use it in ways which you expressly agree to and are aware of. We will never sell your saliva sample. However, we do use and share aggregate, coded information with third parties in order to initiate research and perform business development, as outlined in the Consent Form.
            </p>

            <p className='font-sans text-grey mb-4 italic'>
              The NL Genome Project has no control over the privacy and security of information you choose to share with others.
            </p>
          </div>
        </div>

      </div>
    </div>
  </>
);

export default Hero;
